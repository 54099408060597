<template>
  <ProfileForm />
</template>

<script>
import ProfileForm from '@/components/onboarding/ProfileForm'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    ProfileForm
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_ONBOARD_PROFILE)
  }
}
</script>
