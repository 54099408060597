<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
    <v-card :loading="saving">
      <v-sheet color="secondary lighten-1">
        <v-card-title>{{$t('m.profile.title')}}</v-card-title>
      </v-sheet>
      <v-card-text v-if="gettingProfile">
        <v-progress-linear indeterminate value="15"></v-progress-linear>
      </v-card-text>
      <v-card-text v-else>
        <ValidationProvider :name="$t('m.profile.givenName')" rules="required" v-slot="{ errors }">
          <v-text-field
            :error-messages="errors"
            v-model="givenName"
            :label="$t('m.profile.givenName')"
          />
        </ValidationProvider>
      </v-card-text>
      <v-card-text>
        <ValidationProvider :name="$t('m.profile.firstName')" rules="required" v-slot="{ errors }">
          <v-text-field
            :error-messages="errors"
            v-model="firstName"
            :label="$t('m.profile.firstName')"
          />
        </ValidationProvider>
      </v-card-text>
      <v-card-text>
        <ValidationProvider
          :name="$t('m.profile.email')"
          rules="required|email"
          v-slot="{ errors }"
        >
          <v-text-field :error-messages="errors" v-model="email" :label="$t('m.profile.email')" />
        </ValidationProvider>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn @click="onCancel">{{$t('m.profile.back')}}</v-btn>
        <v-btn :disabled="invalid" :loading="saving" @click="onSubmit">{{$t('m.profile.next')}}</v-btn>
      </v-card-actions>
    </v-card>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      gettingProfile: false,
      firstName: null,
      givenName: null,
      email: null,
      saving: false
    }
  },
  computed: {
    ...mapState('auth', ['uid', 'userAuthEmail']),
    ...mapGetters('auth', ['profile'])
  },
  methods: {
    ...mapActions('auth', ['updateProfile', 'getProfile', 'signOut']),
    async onSubmit () {
      this.rawErrorCode = null
      this.saving = true
      try {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          await this.updateProfile({ uid: this.uid, firstName: this.firstName, givenName: this.givenName, email: this.email })
          this.$router.replace({ name: 'recruit-payment' })
        }
      } catch (err) {
        console.error('error linking email address', err) // eslint-disable-line no-console
      } finally {
        this.saving = false
      }
    },
    async onCancel () {
      await this.signOut()
      this.$router.replace({ name: 'sign-in', params: { redirect: encodeURIComponent('/') } })
    }
  },
  async mounted () {
    this.gettingProfile = true
    await this.getProfile(this.uid)
    this.gettingProfile = false

    const profile = this.profile
    if (profile) {
      this.firstName = profile.firstName
      this.givenName = profile.givenName
      this.email = profile.email
    } else {
      this.email = this.userAuthEmail
    }
  }
}
</script>

<style>
</style>
